:root {
	--background_color: #faeca3 !important;
  --primary_color: #3b2554;
  --secondary_color: #d4c470;
  --menu_item_gray_color: #eef2f6;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'), url("./../../fonts/WorkSans-Light.ttf");
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url("./../../fonts/avenir.otf");
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url("./../../fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url("./../../fonts/Poppins-Light.ttf");
}

img.flag {
	width: 25px;
	height: auto;
}

.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background_color) !important;
}

.loader img {
	max-height: 100px;
	display: flex;
}

#offline {
  background-color: #f5f5f5;
  display: flex;
  font-size: 0.8rem;
  color: #4c4c4c;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 0;
  padding: 1px;
  transition: height 0.5s ease-in-out; 
}

#offline.offline {
  height: 20px;
}

#offline.online {
  height: 0;
  padding: 0;
}


iframe.login {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.MuiListItemAvatar-root {
  text-align: center !important;
}


.loading {
  width: 100%;
  height: 25px;
  text-align: center;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.green {
  color: #008000;
}

iframe.embebbed {
  width: 100%;
  border: 0;
  height: calc(100vh - 251px)
}

/* Profile menu */
.profile-menu {
  background-color: var(--menu_item_gray_color) !important;
  border: none !important;
}

.profile-menu[aria-controls="menu-list-grow"], 
.profile-menu:hover {
  border: none !important;
  background: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover {
    background-color: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover .MuiListItemIcon-root {
  color: white !important;
}

.MuiListItemButton-gutters:hover p {
  color: white !important;
}

/* Locale menu */
.MuiAvatar-colorDefault:hover {
  background: var(--primary_color) !important;
}

.MuiAvatar-colorDefault[aria-controls="menu-list-grow"], .css-1mjiab4-MuiAvatar-root:hover {
  background: var(--primary_color) !important;
}

.locale-list div:hover {
  background: var(--menu_item_gray_color) !important;
}

.locale-list div:hover span {
  color: var(--primary_color) !important;
}

/*Menu toogle*/
.menu-toggle {
  background: var(--primary_color) !important;
  color: white !important;
}

.menu-toggle:hover {
  background: var(--secondary_color) !important;
}

.menu-toggle {
  border-radius: 12px;
}

/*Menu items*/
.menu-item:hover {
  background-color: var(--primary_color) !important;
}

.menu-item:hover .MuiListItemIcon-root {
  color: white !important;
}

.menu-item:hover p {
  color: white !important;
}

.menu-item.Mui-selected {
  color: var(--primary_color) !important;
  background-color: var(--menu_item_gray_color) !important;
}

.menu-item.Mui-selected .MuiListItemIcon-root {
  color: var(--primary_color) !important;
}

main {
  margin-left: 20px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

img.logo {
  max-height: 75px !important;
  margin: auto;
  left: 0;
  right: 0;
}

a {
  text-decoration: none;
}

.service {
  transition: all 0.25s ease;
  user-select: none;
  border-radius: 9px !important;
}

.service::before {
  transition: all 0.35s ease;
}

.service::after {
  transition: all 0.65s ease;
}

.service:hover {
  transform: scale(1.025);
}

.service::before {
  width: 185px;
  height: 185px;
}

.service::after {
  width: 185px;
  height: 185px;
}

.service:hover::before {
  width: 215px;
  height: 215px;
}

.service:hover::after {
  width: 215px;
  height: 215px;
}

.service p {
  color: white;
  font-weight: normal;
}

.service p.url {
  font-size: 14px;
}

.service-content {
  width: 100%;
  text-align: center;
}

.service small {
  width: 100%;
  text-align: center;
  font-size: 15px;
  display: block;
}

.service .MuiSvgIcon-root {
  font-size: 30px;
}

.service.disabled {
  background-image: linear-gradient(to bottom right, #d23d3d, #993a3a);
}

.service.disabled::before {
  background-color: #ce4545;
}

.service.disabled::after {
  background-color: #b33d3d;
}

.service.enabled {
  background-image: linear-gradient(to bottom right, #18bf59, #13823e);
}

.service.enabled::before {
  background-color: #3bca79;
}

.service.enabled::after {
  background-color: #21a254;
}

@media (max-width: 889px) {
  span.logo {
    display: block !important;
  }
}

.MuiToolbar-root {
  padding: 0 !important;
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 75px;
}

@media (max-width: 575px) {
  .service p {
    font-size: 15px;
  }

  .service p.url {
    font-size: 12px;
  }
}

@media (max-width: 599px) {
  main {
    margin-left: 10px !important;
  }
}
